import { apiURL, formatNumber, header } from "@/config/constant";
import axios from "axios";
import Vue from "vue";
import Vuex from "vuex";
import router from '../router'

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: window.localStorage.getItem('token') ? window.localStorage.getItem('token') : null,
    borrowsDates60: {},
    borrowsDates360: [],
    borrowsDatesDetail: [],
    borrowsDate: [],
    borrowsMsisdn: [],
    borrowsRePay: [],
    borrowsDays: [],
    borrow: [],
    len60: false,
    len360: false,
    lenDetail: false,
    lenDate: false,
    lenRePay: false,
    lenDays: false,
    lenMsisdn: false,
    borrowsMsisdnCut: [],
  },
  getters: {
    token: (state) => state.token,
    borrowsDates60: (state) => state.borrowsDates60,
    borrowsDates360: (state) => state.borrowsDates360,
    borrowsDatesDetail: (state) => state.borrowsDatesDetail,
    borrowsDate: (state) => state.borrowsDate,
    borrowsMsisdn: (state) => state.borrowsMsisdn,
    borrowsRePay: (state) => state.borrowsRePay,
    borrowsDays: (state) => state.borrowsDays,
    len360: (state) => state.len360,
    len60: (state) => state.len60,
    lenDetail: (state) => state.lenDetail,
    lenDate: (state) => state.lenDate,
    lenMsisdn: (state) => state.lenMsisdn,
    lenRePay: (state) => state.lenRePay,
    lenDays: (state) => state.lenDays,
    borrowsMsisdnCut: (state) => state.borrowsMsisdnCut,
  },
  mutations: {
    setToken(state, tken) {
      state.token = tken
      window.localStorage.setItem('token', tken)
    },

    setBorrow(state, data) {
      state.borrow = data;
    },

    setBorrowsDatesDetail(state, data) {
      state.borrowsDatesDetail = data;
    },

    setBorrowsDates360(state, data) {
      state.borrowsDates360 = data;
    },
    setBorrowsDates60(state, data) {
      state.borrowsDates60 = data;
    },

    setBorrowsDate(state, data) {
      state.borrowsDate = data;
    },

    setBorrowsMsisdn(state, data) {
      state.borrowsMsisdn = data;
    },

    setBorrowsRePay(state, data) {
      state.borrowsRePay = data;
    },

    setBorrowsDays(state, data) {
      state.borrowsDays = data;
    },

    setLen60(state, data) {
      state.len60 = data;
    },
    setLen360(state, data) {
      state.len360 = data;
    },
    setLenDetail(state, data) {
      state.lenDetail = data;
    },
    setLenDate(state, data) {
      state.lenDate = data;
    },
    setLenMsisdn(state, data) {
      state.lenMsisdn = data;
    },
    setLenRePay(state, data) {
      state.lenRePay = data;
    },
    setLenDays(state, data) {
      state.lenDays = data;
    },
    setborrowsMsisdnCut(state, data) {
      state.borrowsMsisdnCut = data;
    },
  },
  actions: {
    signIn(context, item) {
      try {
        return axios.post(apiURL + `sign-in`, item, { headers: header })
      } catch (error) {
        console.log(error)
        console.log(context)
      }
    },
    isSignIn() {
      // console.log(context)
      try {
        // axios.post(apiURL + `get-token`, { headers: header }).then((res) => {
        //   alert(JSON.stringify(res))
        //   if (res.statusCode == 200) {
        //     window.localStorage.setItem("token", res.data.token);
        //   }else{
        //     window.localStorage.clear()
        //     window.location.replace(`/`);
        //   }
        // })
      } catch (error) {
        console.log(error)
      }
    },

    async getBorrowsMsisdn(context, item) {
      var result = [];
      var res = await axios.post(apiURL + `data-borrowing/get-msisdn`, item, { headers: header })
      if (res.data.responeCode == "202") {
        localStorage.clear()
        window.location.replace("/")
        context.commit("setLenMsisdn", false);
        context.commit("setBorrowsMsisdn", result);
        return
      }
      var len = res.data.responeCode == "200" ? res.data.data.length : 0
      if (len) {
        for (const index in res.data.data) {
          const el = res.data.data[index];

          var resObj = {
            index: parseInt(index) + 1,
            borrow_id: el.borrow_id,
            msisdn: el.msisdn,
            borrow_amount: formatNumber(el.borrow_amount),
            amount: formatNumber(el.amount),
            persent_amount: formatNumber(el.persent_amount),
            paid: formatNumber(el.paid),
            record_date: el.record_date,
            last_update: el.last_update,
            result_code: el.result_code,
            result_desc: el.result_desc,
            remote_ip: el.remote_ip,
            user_id: el.user_id,
            borrow_type: el.borrow_type,
          }
          result.push(resObj)
        }
      } else {
        context.commit("setLenMsisdn", false);
        context.commit("setBorrowsMsisdn", result);
        return
      }
      context.commit("setBorrowsMsisdn", result);
      context.commit("setLenMsisdn", true);
      context.dispatch("isSignIn")
    },

    async getBorrowsDate(context, item) {
      var result = [];
      var res = await axios.post(apiURL + `data-borrowing/get-dates`, item, { headers: header })
      if (res.data.responeCode == "202") {
        localStorage.clear()
        window.location.replace("/")
        context.commit("setLenDate", false);
        context.commit("setBorrowsDate", result);
        return
      }
      var len = res.data.responeCode == "200" ? res.data.data.length : 0
      if (len) {
        for (const index in res.data.data) {
          const el = res.data.data[index];
          var resObj = {
            index: parseInt(index) + 1,
            rpt_date: el.rpt_date,
            all_cnt: formatNumber(el.all_cnt),
            cost_amount: formatNumber(el.cost_amount),
            total_amount: formatNumber(el.total_amount),
            Estimate_incentive_amount: formatNumber(el.Estimate_incentive_amount),
            collect_amount: formatNumber(el.collect_amount),
            incentive_amount: formatNumber(el.incentive_amount),
            GROUP_GET: formatNumber(el.GROUP_GET),
            LMM_GET: formatNumber(el.LMM_GET),
          }
          result.push(resObj)
        }
      } else {
        context.commit("setLenDate", false);
        context.commit("setBorrowsDate", result);
        return
      }
      context.commit("setBorrowsDate", result);
      context.commit("setLenDate", true);
      context.dispatch("isSignIn")
    },

    async getBorrowsDates60(context, type) {
      var result = {};
      var res = await axios.post(apiURL + `data-borrowing/get-when-date`, { type: type }, { headers: header })
      if (res.data.responeCode == "202") {
        localStorage.clear()
        window.location.replace("/")
        context.commit("setLen60", false);
        context.commit("setBorrowsDates60", result);
        return
      }
      var len = res.data.responeCode == "200" ? res.data.data.length : 0
      if (len) {
        var tt7 = 0, tt14 = 0, tt21 = 0, tt30 = 0, tt60 = 0, ttelse = 0
        for (const index in res.data.data) {
          const el = res.data.data[index];
          if (el.AgingDay == "01-07") {
            tt7 += parseFloat(el.debt_amt)
          } else if (el.AgingDay == "08-14") {
            tt14 += parseFloat(el.debt_amt)
          } else if (el.AgingDay == "15-21") {
            tt21 += parseFloat(el.debt_amt)
          } else if (el.AgingDay == "22-30") {
            tt30 += parseFloat(el.debt_amt)
          } else if (el.AgingDay == "31-60") {
            tt60 += parseFloat(el.debt_amt)
          } else {
            ttelse += parseFloat(el.debt_amt)
          }
        }
        var resObj = {
          total_amount: formatNumber(tt7 + tt14 + tt21 + tt30 + tt60 + ttelse),
          series: [{
            name: "ມູນຄ່າ ",
            data: [tt7, tt14, tt21, tt30, tt60, ttelse],
          }],
          chartOptions: {
            chart: {
              height: 500,
              type: type ? type : 'bar',
              toolbar: {
                show: false,
              },
              events: {
                click: function (event, chartContext, config) {
                  if (config.dataPointIndex == 0) { router.push({ path: `/borrow-lost/detail/01-07` }) }
                  if (config.dataPointIndex == 1) { router.push({ path: `/borrow-lost/detail/08-14` }) }
                  if (config.dataPointIndex == 2) { router.push({ path: `/borrow-lost/detail/15-21` }) }
                  if (config.dataPointIndex == 3) { router.push({ path: `/borrow-lost/detail/22-30` }) }
                  if (config.dataPointIndex == 4) { router.push({ path: `/borrow-lost/detail/31-60` }) }
                  if (config.dataPointIndex == 5) { router.push({ path: `/borrow-lost/detail/61-UP` }) }
                },
              }
            },
            colors: ["#0c778a"],
            plotOptions: {
              bar: {
                columnWidth: '45%',
                distributed: true,
                borderRadius: 10,
                dataLabels: {
                  position: 'top',
                },
              },

            },
            states: {
              hover: {
                filter: {
                  type: 'none',
                  value: 5,
                }
              },
              active: {
                allowMultipleDataPointsSelection: false,
                filter: {
                  type: 'lighten',
                  value: 0.35,
                }
              },

            },
            dataLabels: {
              enabled: true,
              formatter: function (val) {
                return formatNumber(val) + " ກິບ";
              },
              offsetY: -20,
              style: {
                fontSize: '12px',
                colors: ["#304758"]
              }
            },
            legend: {
              show: false
            },
            xaxis: {
              categories: [
                ['ໜີ້', '01-07 ວັນ'],
                ['ໝິ້', '08-14 ວັນ'],
                ['ໜີ້', '15-21 ວັນ'],
                ['ໜີ້', '22-30 ວັນ'],
                ['ໜີ້', '30-60 ວັນ'],
                ['ໜີ້', 'ຫຼາຍກ່ວາ 60 ວັນ'],
              ],
              labels: {
                style: {
                  fontFamily: "Noto Sans Lao",
                  fontSize: '12px'
                }
              },
            },
            tooltip: {
              y: {
                formatter: function (value) {
                  return formatNumber(value) + " ກິບ"
                }
              }
            }
          },
        }
        result = resObj
      } else {
        context.commit("setLen60", false);
        context.commit("setBorrowsDates60", result);
        return
      }
      context.commit("setBorrowsDates60", result);
      context.commit("setLen60", true);
      context.dispatch("isSignIn")
    },


    async getBorrowsRePay(context, item) {
      var result = {};
      var res = await axios.post(apiURL + `data-borrowing/repayborrowing`, { item }, { headers: header }).catch((err)=>err)
      if (res.data.responeCode == "202") {
        localStorage.clear()
        window.location.replace("/")
        context.commit("setLenRePay", false);
        context.commit("setBorrowsRePay", result);
        return
      }
      var len = res.data.responeCode == "200" ? res.data.data.length : 0
      if (len) {
        var tt7 = 0, tt14 = 0, tt21 = 0, tt30 = 0, tt60 = 0, ttelse = 0
        for (const index in res.data.data) {
          const el = res.data.data[index];
          if (el.clear_date == 1) {
            tt7 += parseFloat(el.cnt)
          } else if (el.clear_date == 2) {
            tt14 += parseFloat(el.cnt)
          } else if (el.clear_date == 3) {
            tt21 += parseFloat(el.cnt)
          } else if (el.clear_date == 4) {
            tt30 += parseFloat(el.cnt)
          } else if (el.clear_date == 5) {
            tt60 += parseFloat(el.cnt)
          } else {
            ttelse += parseFloat(el.cnt)
          }
        }
        var resObj = {
          total_amount: formatNumber(tt7 + tt14 + tt21 + tt30 + tt60 + ttelse),
          series: [{
            name: "ມູນຄ່າ ",
            data: [tt7, tt14, tt21, tt30, tt60, ttelse],

          }],
          chartOptions: {
            chart: {
              height: 500,
              type: item.type ? item.type : 'bar',
              toolbar: {
                show: false,
              },
            },
            colors: ["#0c778a"],
            plotOptions: {
              bar: {
                columnWidth: '45%',
                distributed: true,
                borderRadius: 10,
                dataLabels: {
                  position: 'top',
                },
              },
            },
            states: {
              hover: {
                filter: {
                  type: 'none',
                  value: 5,
                }
              },
              active: {
                allowMultipleDataPointsSelection: false,
                filter: {
                  type: 'lighten',
                  value: 0.35,
                }
              },

            },
            dataLabels: {
              enabled: true,
              formatter: function (val) {
                return formatNumber(val) + " ກິບ";
              },
              offsetY: -20,
              style: {
                fontSize: '12px',
                colors: ["#304758"]
              }
            },
            legend: {
              show: false
            },
            xaxis: {
              categories: [
                ['ຄືນໜີ້', '01-07 ວັນ'],
                ['ຄືນໝິ້', '08-14 ວັນ'],
                ['ຄືນໜີ້', '15-21 ວັນ'],
                ['ຄືນໜີ້', '22-30 ວັນ'],
                ['ຄືນໜີ້', '30-60 ວັນ'],
                ['ຄືນໜີ້', 'ຫຼາຍກ່ວາ 60 ວັນ'],
              ],
              labels: {
                style: {
                  fontFamily: "Noto Sans Lao",
                  fontSize: '12px'
                }
              },

            },
            tooltip: {
              y: {
                formatter: function (value) {
                  return formatNumber(value) + " ກິບ"
                }
              }
            }
          },
        }
        result = resObj
      } else {
        context.commit("setLenRePay", false);
        context.commit("setBorrowsRePay", result);
        return
      }
      context.commit("setBorrowsRePay", result);
      context.commit("setLenRePay", true);
    },

    async getBorrowsDays(context, item) {
      var result = {};
      var res = await axios.post(apiURL + `data-borrowing/days`, { item }, { headers: header }).catch((err)=>err)
      console.log(item);
      if (res.data.responeCode == "202") {
        localStorage.clear()
        window.location.replace("/")
        context.commit("setLenDays", false);
        context.commit("setBorrowsDays", result);
        return
      }
      var len = res.data.responeCode == "200" ? res.data.data.length : 0
      if (len) {
        var all_cnt=[], days = [] ,tt=0
        for (const index in res.data.data) {
          const el = res.data.data[index];
          all_cnt.push(el.all_cnt)
          days.push(el.rpt_date)
          tt+=parseFloat(el.all_cnt)
        }
        var resObj = {
          total_amount: formatNumber(tt),
          series: [{
            name: "ມູນຄ່າ ",
            data: all_cnt,

          }],
          chartOptions: {
            chart: {
              height: 500,
              type: item.type ? item.type : 'bar',
              toolbar: {
                show: false,
              },

              events: {
                click: function (event, chartContext, config) {
                  for (const index in res.data.data) {
                    const el = res.data.data[index];
                    if (config.dataPointIndex == index) { router.push({ path: `/borrow-summary/detail/${el.rpt_date}/${item.msisdn}` }) }
                  }
                 

                },
              }

            },
            colors: ["#0c778a"],
            plotOptions: {
              bar: {
                columnWidth: '50%',
                distributed: true,
                borderRadius: 10,
                dataLabels: {
                  position: 'top',
                  // orientation: 'vertical',
                  fontFamily:"Noto Sans Lao"
                },
              },
            },
            states: {
              hover: {
                filter: {
                  type: 'none',
                  value: 5,
                }
              },
              active: {
                allowMultipleDataPointsSelection: false,
                filter: {
                  type: 'lighten',
                  value: 0.35,
                }
              },

            },
            dataLabels: {
              enabled: true,
              formatter: function (val) {
                return formatNumber(val);
              },
              offsetY: -20,
              style: {
                fontSize: '12px',
                colors: ["#304758"]
              },
            },
            legend: {
              show: false
            },
            xaxis: {
              categories: days,
              labels: {
                style: {
                  fontFamily: "Noto Sans Lao",
                  fontSize: '12px'
                }
              },

            },
            tooltip: {
              y: {
                formatter: function (value) {
                  return formatNumber(value) 
                }
              }
            }
          },
        }
        result = resObj
      } else {
        context.commit("setLenDays", false);
        context.commit("setBorrowsDays", result);
        return
      }
      context.commit("setBorrowsDays", result);
      context.commit("setLenDays", true);
    },

    async getBorrowsDates360(context, data) {
      var result = [];
      var res = await axios.post(apiURL + `data-borrowing/get-when-date`, { data: data }, { headers: header })
      if (res.data.responeCode == "202") {
        localStorage.clear()
        window.location.replace("/")
        context.commit("setLen360", false);
        context.commit("setBorrowsDates360", result);
        return
      }
      var len = res.data.responeCode == "200" ? res.data.data.length : 0
      if (len) {
        const el = res.data.data;
        result = el
      } else {
        context.commit("setLen360", false);
        context.commit("setBorrowsDates360", result);
        return
      }
      context.commit("setBorrowsDates360", result);
      context.commit("setLen360", true);
      context.dispatch("isSignIn")
    },
    async getBorrowsDatesDetail(context, item) {
      var result = [];
      var res = await axios.post(apiURL + `data-borrowing/get-when-date-detail`, item, { headers: header })
      if (res.data.responeCode == "202") {
        localStorage.clear()
        window.location.replace("/")
        context.commit("setLenDetail", false);
        context.commit("setBorrowsDatesDetail", result);
        return
      }
      var len = res.data.responeCode == "200" ? res.data.data.length : 0
      if (len > 0) {
        result = res.data.data
      } else {
        context.commit("setLenDetail", false);
        context.commit("setBorrowsDatesDetail", result);
        return
      }
      context.commit("setBorrowsDatesDetail", result);
      context.commit("setLenDetail", true);
      context.dispatch("isSignIn")
    },
    getBorrowsDatesAllDetail(context, item) {
      return axios.post(apiURL + `data-borrowing/get-when-date-detail`, item, { headers: header })
    },





    async getBorrowsHours(context, item) {
      var result = {};
      var res = await axios.post(apiURL + `data-borrowing/hours`, { item }, { headers: header }).catch((err)=>err)
      console.log(item);
      if (res.data.responeCode == "202") {
        localStorage.clear()
        window.location.replace("/")
        context.commit("setLenDays", false);
        context.commit("setBorrowsDays", result);
        return
      }
      var len = res.data.responeCode == "200" ? res.data.data.length : 0
      if (len) {
        var all_cnt=[], days = [] ,tt=0
        for (const index in res.data.data) {
          const el = res.data.data[index];
          all_cnt.push(el.all_cnt)
          days.push(el.rpt_hour)
          tt+=parseFloat(el.all_cnt)
        }
        var resObj = {
          total_amount: formatNumber(tt),
          series: [{
            name: "ມູນຄ່າ ",
            data: all_cnt,

          }],
          chartOptions: {
            chart: {
              height: 500,
              type: item.type ? item.type : 'bar',
              toolbar: {
                show: false,
              },

            },
            colors: ["#0c778a"],
            plotOptions: {
              bar: {
                columnWidth: '50%',
                distributed: true,
                borderRadius: 10,
                dataLabels: {
                  position: 'top',
                  // orientation: 'vertical',
                  fontFamily:"Noto Sans Lao"
                },
              },
            },
            states: {
              hover: {
                filter: {
                  type: 'none',
                  value: 5,
                }
              },
              active: {
                allowMultipleDataPointsSelection: false,
                filter: {
                  type: 'lighten',
                  value: 0.35,
                }
              },

            },
            dataLabels: {
              enabled: true,
              formatter: function (val) {
                return formatNumber(val);
              },
              offsetY: -20,
              style: {
                fontSize: '12px',
                colors: ["#304758"]
              },
            },
            legend: {
              show: false
            },
            xaxis: {
              categories: days,
              labels: {
                style: {
                  fontFamily: "Noto Sans Lao",
                  fontSize: '12px'
                }
              },

            },
            tooltip: {
              y: {
                formatter: function (value) {
                  return formatNumber(value) 
                }
              }
            }
          },
        }
        result = resObj
      } else {
        context.commit("setLenDays", false);
        context.commit("setBorrowsDays", result);
        return
      }
      context.commit("setBorrowsDays", result);
      context.commit("setLenDays", true);
    },


    async getBorrowsMsisdnCut(context, item) {
      var result = {};
      //var res = await axios.post(apiURL + `data-borrowing/cut-balance/hours`, { item }, { headers: header }).catch((err)=>err)
      //console.log(item);
      var msisdn = item.msisdn;

      var res = await axios.get(apiURL + `data-borrowing/cut-balance/${msisdn}`).catch((err)=>err);
      //console.log(apiURL + `data-borrowing/cut-balance/${msisdn}`);
      //console.log(res.data)
      if (res.data.responeCode == "202") {
        localStorage.clear()
        window.location.replace("/")
        //context.commit("setLenDays", false);
        context.commit("setborrowsMsisdnCut", result);
        return
      }

      var len = res.data.responeCode == "200" ?true:false;
      if (len) {
        result = res.data;
        if(result.resultCode=='20'){
          console.log(result.resultDesc)
        }else{
          console.log(result.resultDesc)
        }
      } else {
        //context.commit("setLenDays", false);
        context.commit("setborrowsMsisdnCut", result);
        return
      }
      context.commit("setborrowsMsisdnCut", result);
      //context.commit("setLenDays", true);
    },



  },
});
