<template>
  <v-app class="app">
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
};
</script>
<style lang="css">
@font-face {
  font-size: 1.8rem;
  font-family: "Noto Sans Lao";
  src: local("NotoSansLao"),
    url(./assets/fonts/NotoSansLao-Regular.ttf) format("truetype");
}

.description {
  font-size: 1.8rem;
  font-family: "Noto Sans Lao";
}

html {
  scroll-behavior: smooth;
}

.app {
  font-family: "Noto Sans Lao";
}

.v-dialog {
  font-family: "Noto Sans Lao";
}

.v-avatar {
  font-family: "Noto Sans Lao";
}

.v-text-field label {
  font-size: 1rem !important;
}

.v-btn {
  text-transform: none !important;
  font-size: 1.2rem !important;
}

.v-data-table {
  padding: 20px !important;
}

.v-data-table th {
  padding: 10px 0px !important;
  font-size: 16px !important;
}

/* .v-data-table td {
  font-size: 14px !important;
} */
a:link {
  text-decoration: none;
  color: black;
}

a:visited {
  text-decoration: none;
  color: black;
}

a:hover {
  text-decoration: underline;
  color: black;
}

a:active {
  text-decoration: none;
  color: black;
}
</style>

