//const apiURL = "http://172.28.12.114:8002/"
const apiURL = "https://loandata.mmoney.la/"
const header = {
  'Content-Type': 'application/json',
  'Authorization': 'Bearer ' + window.localStorage.getItem('token'),
}
const formatNumber = (numbers) => {
  var num = numbers.toString().split(".");
  var number = 0;
  if (num.length > 1) {
    number =
      String(num[0]).replace(/(.)(?=(\d{3})+$)/g, "$1,") +
      "." +
      String(num[1]).replace(/(.)(?=(\d{3})+$)/g, "$1,");
  } else {
    number = String(parseInt(numbers)).replace(/(.)(?=(\d{3})+$)/g, "$1,");
  }
  return number
}

const formatString = (strings) => {
  var str = strings.toString().split(",");
  var string = ""
  if (str.length > 1) {
    for (const key in str) {
      string += '' + str[key]
    }
  } else {
    string = strings
  }
  return parseFloat(string)
}
export {
  apiURL,
  header,
  formatNumber,
  formatString,
}
