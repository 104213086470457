import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'SignIn',
    component: () => import('../views/SignIn.vue'),
  },
  {
    path: '/borrow-date',
    name: 'all',
    component: () => import('../views/Home.vue'),
    beforeEnter: (to, from, next) => {
      var token = window.localStorage.getItem('token') ? window.localStorage.getItem('token') : null
      if (token != null && token != undefined) next()
      else next({ path: '/' })
    },
    children: [
      {
        path: '/',
        name: 'Date',
        component: () => import('../views/BorrowDate.vue')
      },
      {
        path: '/borrow-date',
        name: 'Date2',
        component: () => import('../views/BorrowDate.vue')
      },
      {
        path: '/borrow-msisdn',
        name: 'msisdn',
        component: () => import('../views/BorrowMsisdn.vue')
      },
      {
        path: '/borrow-lost',
        name: 'lost',
        component: () => import('../views/BorrowLost.vue')
      },
      {
        path: '/borrow-repay',
        name: 'repay',
        component: () => import('../views/BorrowRePay.vue')
      },
      {
        path: '/borrow-days',
        name: 'days',
        component: () => import('../views/BorrowLostDays.vue')
      },
      {
        path: '/borrow-history',
        name: 'lostHistory',
        component: () => import('../views/BorrowHistory.vue')
      },
      {
        path: '/borrow-lost/detail/:agingday',
        name: 'lost2',
        component: () => import('../views/BorrowLostDetail.vue')
      },
      {
        path: '/borrow-summary',
        name: 'summary',
        component: () => import('../views/BorrowSummary.vue')
      },
      {
        path: '/borrow-summary/detail/:ryear/:rmonth/:rday/:rmsisdn',
        name: 'lost3',
        component: () => import('../views/BorrowSummary.vue')
      },
    ]
  },

]

const router = new VueRouter({
  routes,
  mode: 'history'
})

export default router
